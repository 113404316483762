import React from "react";
import { Link } from "gatsby";

const BackToHome = () => {
  return (
    <div className="button align-self-center">
      <Link to="/">
        <button
          title="Back to homepage"
          className="btn btn-link p-0 border-0 transform-scale-h"
        >
          <i className="icon-c icon-arrow-left" />
          Back to homepage
        </button>
      </Link>
    </div>
  );
};

export default BackToHome;
