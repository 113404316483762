import React, { Fragment } from "react";
import "../assets/sass/style.scss";

import Seo from "../blocks/seo/Seo";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleGeneric from "../blocks/page-title/PageTitleGeneric";
import BackToHome from "../components/button/BackToHome";

// TODO: make page shown for all 404 pages

const NotFoundPage = () => {
  return (
    <Fragment>
      <Seo
        title="404 Page"
        robots="noindex, nofollow"
        bodyAttributes={{
          class: "error404 bg-fixed bg-line",
        }}
      />

      <Header />

      <main id="main" className="site-main bg-half-ring-up">
        <PageTitleGeneric title="404" />

        <div id="page-content" className="block">
          <div className="wrapper">
            <p className="spacer p-top-lg w-50 m-0">
              The page you were looking for couldn't be found.
            </p>

            <div className="spacer p-top-xs d-xl-flex d-lg-flex d-md-flex justify-content-between block m-top-extrasmall">
              <BackToHome />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default NotFoundPage;
